import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import IOSSwitch from "../Common/Switch";
import { useNavigate, useParams } from "react-router-dom";
import { TreeSelect } from "primereact/treeselect";
import Tabs from "react-bootstrap/Tabs";
import { MultiSelect } from "primereact/multiselect";
import Tab from "react-bootstrap/Tab";
import DropZone from "../Common/DropZone";
import { BreadcrumbRight, DeleteIcon } from "../../Assets/Icons";
import ProductsAssets from "./ProductsAssets";
import { useSelector } from "react-redux";
import apiClient from "../../apiClient";
import {
  CREATE_PRODUCT,
  EDIT_PRODUCT,
  FETCH_CATEGORY_LIST,
  FETCH_CATEGORY_TREE,
  FETCH_PRODUCT_DETAIL,
  FETCH_SINGLE_PRODUCTS,
} from "../../apiClient/apiEndpoints";
import { addChildrenRecursively, getExpandedKeys } from "../../utils/common";
import * as yup from "yup";

const PRODUCT_TYPE = {
  SINGLE: "SINGLE",
  CONFIGURABLE: "CONFIGURABLE",
};

const defaultValues = {
  name: "",
  description: "",
  features: "",
  specifications: "",
  basePrice: "",
  mrp: "",
  discount: "",
  hsnCode: "",
  lnCode: "",
  height: "",
  width: "",
  depth: "",
  active: true,
  type: PRODUCT_TYPE.SINGLE,
};

const defaultImageValues = {
  topView: null,
  frontView: null,
};
const defaultPreviewValues = {
  topView: "",
  frontView: "",
};

const validationSchema = yup.object({
  name: yup.string().trim().required("Name is required"),
  categoryId: yup.string().trim().required("Category is required."),
  description: yup.string().trim().optional(),
  features: yup.string().trim().optional(),
  specifications: yup.string().trim().optional(),
  mrp: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value)) // Transform to number
    .min(0)
    .when("type", {
      is: PRODUCT_TYPE.SINGLE,
      then: (schema) => schema.required("MRP is required"),
    }),
  discount: yup.string().trim().optional(),
  hsnCode: yup.string().optional(),
  lnCode: yup.string().when("type", {
    is: PRODUCT_TYPE.SINGLE,
    then: (schema) => schema.trim().required("LN Code is required"),
  }),
  height: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable()
    .min(0)
    .optional(),
  width: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable()
    .min(0)
    .optional(),
  depth: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable()
    .min(0)
    .optional(),
  active: yup.boolean().required("Status is required"),
  type: yup.string().required("Product type is required"),
});

const AddProduct = ({ edit }) => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const configData = useSelector((state) => state.config);
  const [currentTab, setCurrentTab] = useState("details");
  const [productFormData, setProductFormData] = useState(defaultValues);
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);
  const [loading, setLoading] = useState(true);
  const [productView, setProductView] = useState(defaultImageValues);
  const [productViewPreview, setProductViewPreview] = useState(defaultPreviewValues);
  const [error, setError] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState({});
  const [newProductId, setNewProductId] = useState(null);
  const [singleProductList, setSingleProductList] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const API_URL = configData.config?.API_URL;
  const singleProduct = productFormData?.type === PRODUCT_TYPE.SINGLE;

  const handleTabSelect = (selectedTab) => {
    if (selectedTab === "back") {
      navigate("/products");
      return;
    }
    setCurrentTab(selectedTab);
  };

  const formChangeHandler = (e) => {
    setProductFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const checkboxHandler = (value) => {
    setProductFormData((prev) => ({ ...prev, type: value }));
  };

  const fetchCategoryOptions = async (categoryId) => {
    const queryParams = {};
    if (categoryId) {
      queryParams.categoryId = categoryId;
    }
    const res = await apiClient.get(`${API_URL}${FETCH_CATEGORY_LIST}`, { params: queryParams });
    return res?.map((item) => ({ key: item.uuid, label: item.name, leaf: false, children: [] }));
  };

  const nodeExpandHandler = async (event) => {
    const node = event.node;
    setExpandedKeys((prev) => ({ ...prev, [node.key]: true }));
    if (!node.children || node?.children?.length === 0) {
      const newChildren = await fetchCategoryOptions(node.key);
      const updatedTree = addChildrenRecursively(node.key, categoryOptions, newChildren);
      setCategoryOptions(updatedTree);
    }
  };

  const nodeToggleHandler = (e) => {
    setExpandedKeys(e.value);
  };

  const formSubmitHandler = async () => {
    setLoading(true);
    if (productId) {
      const body = {
        ...productFormData,
        categoryId: selectedNodeKey,
        price: productFormData?.mrp,
        ...(!singleProduct && { assignedProducts: selectedProducts.map((item) => item.code) || [] }),
      };
      try {
        validationSchema.validateSync(body);
        setError(false);
        const formData = new FormData();

        !productView.topView
          ? (body.topView = productViewPreview.topView)
          : formData.append("topView", productView.topView);

        !productView.frontView
          ? (body.frontView = productViewPreview.frontView)
          : formData.append("frontView", productView.frontView);

        const stringifiedBody = JSON.stringify(body);
        formData.append("data", stringifiedBody);

        await apiClient.put(`${API_URL}${EDIT_PRODUCT}/${productId}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      } catch (error) {
        console.log(error);
        setError(true);
      }
    } else {
      const body = {
        ...productFormData,
        categoryId: selectedNodeKey,
        price: productFormData?.mrp,
        ...(!singleProduct && { assignedProducts: selectedProducts.map((item) => item.code) || [] }),
      };
      try {
        validationSchema.validateSync(body);
        setError(false);
        const stringifiedBody = JSON.stringify(body);

        const formData = new FormData();
        if (productView.topView) {
          formData.append("topView", productView.topView);
        }
        if (productView.frontView) {
          formData.append("frontView", productView.frontView);
        }

        formData.append("data", stringifiedBody);

        const newProduct = await apiClient.post(`${API_URL}${CREATE_PRODUCT}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        setNewProductId(newProduct?.uuid);
      } catch (error) {
        setError(true);
      }
    }
    // setProductFormData(defaultValues);
    // setProductView(defaultImageValues);
    // setProductViewPreview(defaultPreviewValues);
    setLoading(false);
    // singleProduct ? setCurrentTab("assets") : navigate("/products");
  };

  useEffect(() => {
    setProductViewPreview(
      productView?.topView ? (prev) => ({ ...prev, topView: URL.createObjectURL(productView.topView) }) : ""
    );
    setProductViewPreview(
      productView?.frontView ? (prev) => ({ ...prev, frontView: URL.createObjectURL(productView.frontView) }) : ""
    );
  }, [productView.topView, productView.frontView]);

  const loadPage = async () => {
    const res = await fetchCategoryOptions();
    setCategoryOptions(res);
    setLoading(false);
  };

  const fetchCategoryTree = async (categoryId) => {
    const res = await apiClient.get(`${API_URL}${FETCH_CATEGORY_TREE}/${categoryId}`);
    setCategoryOptions(res);
    const keys = getExpandedKeys(res);
    setExpandedKeys(keys);
    setSelectedNodeKey(categoryId);
    setLoading(false);
  };

  const fetchProductInfo = async () => {
    const res = await apiClient.get(`${API_URL}${FETCH_PRODUCT_DETAIL}/${productId}`);
    if (res) {
      const productDetails = {
        name: res.name,
        description: res.data?.description || "",
        specifications: res.data?.specifications || "",
        features: res.data?.features || "",
        basePrice: res.data?.basePrice || "",
        mrp: res.data?.mrp || "",
        discount: res.data?.discount || "",
        hsnCode: res.hsnCode || "",
        lnCode: res.lnCode || "",
        height: res.data?.measurements?.height || "",
        width: res.data?.measurements?.width || "",
        depth: res.data?.measurements?.depth || "",
        active: res.active,
        type: res.type,
      };
      setProductFormData(productDetails);
      setProductViewPreview({
        topView: res.data?.productViews?.topView || "",
        frontView: res.data?.productViews?.frontView || "",
      });
      if (res?.children?.length > 0) {
        setSelectedProducts(res.children?.map((item) => ({ name: item.name, code: item.uuid })));
      }
      if (edit) {
        fetchCategoryTree(res.productCategory?.uuid);
      } else {
        setCategoryOptions([{ key: res.productCategory?.uuid, label: res.productCategory?.name }]);
        setSelectedNodeKey(res.productCategory.uuid);
        setLoading(false);
      }
    }
  };

  const fetchSingleProducts = async () => {
    const res = await apiClient.get(`${API_URL}${FETCH_SINGLE_PRODUCTS}`);
    const productOptions = res.map((item) => ({ name: item.name, code: item.uuid }));
    setSingleProductList(productOptions);
  };

  useEffect(() => {
    if (productId) {
      fetchProductInfo();
    } else {
      loadPage();
    }
    fetchSingleProducts();
  }, []);

  return (
    <div className="add-product">
      <Tabs activeKey={currentTab} onSelect={handleTabSelect}>
        <Tab
          title={
            <span>
              {" "}
              <span className="tab-back-icon">
                {" "}
                <BreadcrumbRight className="dsdas" />{" "}
              </span>{" "}
              Product List{" "}
            </span>
          }
          eventKey="back"
        />

        <Tab eventKey="details" title="Basic Details">
          <div className="basic-details">
            <div className="row">
              <div className="col-lg-4 d-flex align-item-center justify-content-start gap-3 flex-column">
                <div className="form-group">
                  <p>
                    Product Name <span> * </span>
                  </p>
                  <TextField
                    name="name"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter Product Name"
                    size="small"
                    value={productFormData.name}
                    onChange={formChangeHandler}
                    disabled={!edit}
                  />
                </div>

                <div className="  select-type mt-2 pt-2">
                  <p htmlFor=" exampleFormControlInput1">
                    Product Type <span>*</span>{" "}
                  </p>
                  <div className="select-options">
                    <div
                      className="form-check radio quizhead ps-0 pt-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => checkboxHandler(PRODUCT_TYPE.SINGLE)}
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={productFormData.type === PRODUCT_TYPE.SINGLE}
                        id={PRODUCT_TYPE.SINGLE}
                      />
                      <p id={PRODUCT_TYPE.SINGLE} className="form-check-label m-0" style={{ cursor: "pointer" }}>
                        Single
                      </p>
                    </div>
                    <div
                      className="form-check radio quizhead ps-0 pt-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => checkboxHandler(PRODUCT_TYPE.CONFIGURABLE)}
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={productFormData.type === PRODUCT_TYPE.CONFIGURABLE}
                        id={PRODUCT_TYPE.CONFIGURABLE}
                      />
                      <p id={PRODUCT_TYPE.CONFIGURABLE} className="form-check-label m-0 " style={{ cursor: "pointer" }}>
                        Configurable
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="form-group product-add">
                  <p>Product Front View</p>
                  <div className="profile-Logo">
                    {!(productView.frontView || productViewPreview.frontView) && (
                      <DropZone
                        edit={edit}
                        imageFor="Front View"
                        product={true}
                        onFileUpload={(file) => setProductView((prev) => ({ ...prev, frontView: file }))}
                      />
                    )}
                    {productViewPreview?.frontView && (
                      <div className="groupEditLogo position-relative ">
                        <img src={productViewPreview.frontView} alt="File Preview" />
                        {edit && (
                          <span
                            className="closeicon position-absolute"
                            onClick={() => {
                              setProductView((prev) => ({ ...prev, frontView: null }));
                              setProductViewPreview((prev) => ({ ...prev, frontView: "" }));
                            }}
                          >
                            <DeleteIcon />
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group product-add">
                  <p>Product Top View</p>
                  <div className="profile-Logo">
                    {!(productView?.topView || productViewPreview.topView) && (
                      <DropZone
                        edit={edit}
                        product={true}
                        imageFor="Top View"
                        onFileUpload={(file) => setProductView((prev) => ({ ...prev, topView: file }))}
                      />
                    )}
                    {productViewPreview?.topView && (
                      <div className="groupEditLogo position-relative ">
                        <img src={productViewPreview.topView} alt="File Preview" />
                        {edit && (
                          <span
                            className="closeicon position-absolute"
                            onClick={() => {
                              setProductView((prev) => ({ ...prev, topView: null }));
                              setProductViewPreview((prev) => ({ ...prev, topView: "" }));
                            }}
                          >
                            <DeleteIcon />
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group des">
                  <p>
                    Select Category <span>*</span>
                  </p>
                  <TreeSelect
                    value={selectedNodeKey}
                    onChange={(e) => setSelectedNodeKey(e.value)}
                    onNodeExpand={nodeExpandHandler}
                    expandedKeys={expandedKeys}
                    options={categoryOptions}
                    className="w-100"
                    placeholder="Select Category"
                    filter
                    onToggle={nodeToggleHandler}
                    disabled={!edit}
                  />
                </div>
              </div>
              {!singleProduct && (
                <div className="col-lg-4">
                  <div className="form-group des prime-select">
                    <p>
                      Select Products <span>*</span>
                    </p>
                    <MultiSelect
                      value={selectedProducts}
                      onChange={(e) => setSelectedProducts(e.value)}
                      options={singleProductList}
                      optionLabel="name"
                      filter
                      placeholder="Select Products"
                      maxSelectedLabels={3}
                      className="w-100"
                    />
                  </div>
                </div>
              )}
              {singleProduct && (
                <div className="col-lg-4">
                  <div className="form-group">
                    <p>
                      Product Price <span>*</span>
                    </p>
                    <TextField
                      name="mrp"
                      value={productFormData.mrp}
                      onChange={formChangeHandler}
                      variant="outlined"
                      fullWidth
                      placeholder="Enter MRP"
                      size="small"
                      disabled={!edit}
                    />
                  </div>
                </div>
              )}

              {singleProduct && (
                <>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <p>
                        Product Base Price
                      </p>
                      <TextField
                        name="basePrice"
                        value={productFormData.basePrice}
                        onChange={formChangeHandler}
                        variant="outlined"
                        fullWidth
                        placeholder="Enter Base Price"
                        size="small"
                        disabled={!edit}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="form-group">
                      <p>Discount (in %)</p>
                      <TextField
                        name="discount"
                        value={productFormData.discount}
                        onChange={formChangeHandler}
                        variant="outlined"
                        fullWidth
                        placeholder="Enter Discount"
                        size="small"
                        disabled={!edit}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="form-group">
                      <p>
                        Product HSN Code <span> * </span>
                      </p>
                      <TextField
                        name="hsnCode"
                        value={productFormData.hsnCode}
                        onChange={formChangeHandler}
                        variant="outlined"
                        fullWidth
                        placeholder="Enter HSN Code"
                        size="small"
                        disabled={!edit}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="form-group">
                      <p>
                        Product LN Code <span> * </span>
                      </p>
                      <TextField
                        name="lnCode"
                        value={productFormData.lnCode}
                        onChange={formChangeHandler}
                        variant="outlined"
                        fullWidth
                        placeholder="Enter LN Code"
                        size="small"
                        disabled={!edit}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="form-group des">
                      <p>Description</p>
                      <TextField
                        name="description"
                        value={productFormData.description}
                        onChange={formChangeHandler}
                        variant="outlined"
                        fullWidth
                        placeholder="Enter Description"
                        multiline
                        rows={5}
                        className="dessss"
                        size="small"
                        disabled={!edit}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="form-group des">
                      <p>Features</p>
                      <TextField
                        name="features"
                        value={productFormData.features}
                        onChange={formChangeHandler}
                        variant="outlined"
                        fullWidth
                        placeholder="Enter Features"
                        multiline
                        rows={5}
                        className="dessss"
                        size="small"
                        disabled={!edit}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="form-group des">
                      <p>Specification</p>
                      <TextField
                        name="specifications"
                        value={productFormData.specifications}
                        onChange={formChangeHandler}
                        variant="outlined"
                        fullWidth
                        placeholder="Enter Specification"
                        multiline
                        rows={5}
                        className="dessss"
                        size="small"
                        disabled={!edit}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="form-group">
                      <p>Height (in cm)</p>
                      <TextField
                        name="height"
                        value={productFormData.height}
                        onChange={formChangeHandler}
                        variant="outlined"
                        fullWidth
                        placeholder="Enter Height"
                        size="small"
                        disabled={!edit}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="form-group">
                      <p>Width (in cm)</p>
                      <TextField
                        name="width"
                        value={productFormData.width}
                        onChange={formChangeHandler}
                        variant="outlined"
                        fullWidth
                        placeholder="Enter Width"
                        size="small"
                        disabled={!edit}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="form-group">
                      <p>Depth (in cm)</p>
                      <TextField
                        name="depth"
                        value={productFormData.depth}
                        onChange={formChangeHandler}
                        variant="outlined"
                        fullWidth
                        placeholder="Enter Depth"
                        size="small"
                        disabled={!edit}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="col-lg-4">
                <div className="form-group">
                  <p>Product Status</p>
                  <div className="form-group selectall-option">
                    <div className="d-flex align-items-center justify-content-start">
                      <FormControlLabel
                        className="me-0"
                        name="active"
                        checked={productFormData.active}
                        onChange={(e) => setProductFormData((prev) => ({ ...prev, active: e.target.checked }))}
                        control={<IOSSwitch sx={{ m: 1 }} />}
                        disabled={!edit}
                      />
                      <span className={productFormData.active ? "active-label" : "inactive-label"}>
                        {productFormData.active ? "Active" : "Inactive"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer-btn mt-3 justify-content-between">
              <p className="error"> {error ? "Fill all the required fields" : ""} </p>
              <div className="d-flex align-item-center justify-content-end gap-3">
                <button className="createbtn cancelbtn" onClick={() => navigate("/products")}>
                  Cancel
                </button>
                {edit &&
                  (!loading ? (
                    <button className="createbtn" onClick={formSubmitHandler}>
                      Save
                    </button>
                  ) : (
                    <button className="loading save">
                      <svg viewBox="25 25 50 50">
                        <circle r="20" cy="50" cx="50">
                          {""}
                        </circle>
                      </svg>
                    </button>
                  ))}
              </div>
            </div>
          </div>
        </Tab>
        {singleProduct && (productId || newProductId) && (
          <Tab eventKey="assets" title="Assets">
            <ProductsAssets productId={productId || newProductId} />
          </Tab>
        )}
      </Tabs>
    </div>
  );
};

export default AddProduct;
