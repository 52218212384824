export const FETCH_CATEGORY_LIST = "/api/category/list";
export const CREATE_NEW_CATEGORY_URL = "/api/category/create";
export const EDIT_CATEGORY_URL = "/api/category/edit";
export const FETCH_PRODUCT_LIST = "/api/product/list";
export const FETCH_PARENT_CATEGORIES = "/api/category/breadcrumb";
export const CREATE_PRODUCT = "/api/product/create";
export const FETCH_PRODUCT_DETAIL = "/api/product/details";
export const FETCH_CATEGORY_TREE = "/api/category/tree";
export const EDIT_PRODUCT = "/api/product/edit";
export const ASSET_UPLOAD_URL = "/api/product/assets";
export const CREATE_RESOURCE_URL = "/api/product/resource/create";
export const EDIT_RESOURCE_URL = "/api/product/resource/edit";
export const LIST_RESOURCE_URL = "/api/product/resource/list";
export const FETCH_CATEGORY_FILTER_LIST = "/api/category/all";
export const FETCH_DASHBOARD_STATS = "/api/dashboard/stats";
export const FETCH_DASHBOARD_GRAPH_STATS = "/api/dashboard/graphstats";
export const FETCH_GROUP_HIERARCHY = "/api/dashboard/subgroups";
export const FETCH_GROUP_GRAPH_STATS = "/api/dashboard/group-graph";
export const FETCH_TIME_GRAPH_STATS = "/api/dashboard/time-graph";
export const FETCH_PLATFORM_GRAPH_STATS = "/api/dashboard/total-platform-graph";
export const UPLOAD_BULK_CATEGORY = "/api/bulk/add-bulk-category";
export const UPLOAD_BULK_PRODUCTS = "/api/bulk/add-bulk-product";
export const FETCH_SINGLE_PRODUCTS = "/api/product/list/single";
export const FETCH_BOM_LIST = "/api/bom/list";
export const FETCH_SESSION_LIST = "/api/session/list";
export const FETCH_BOM_REPORT = "/api/report/boms";
export const FETCH_CATEGORY_REPORT = "/api/report/category";
export const FETCH_PRODUCT_REPORT = "/api/report/product";
export const FETCH_SESSION_REPORT = "/api/report/session";
export const FETCH_BOM_PDF = "/api/bom/pdf";
export const FETCH_BOM_DETAILS = "/api/bom/detail";
export const FETCH_USER_SESSION_LIST = "/api/session/user-session"
