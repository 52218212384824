import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BackIcon, CancelIcon, EXLDownloadIcon, SearchesCIcon, SpinnerIcon } from "../Assets/Icons";
import Skeleton from "@mui/material/Skeleton";
import Pagination from "@mui/material/Pagination";
import apiClient from "../apiClient";
import { BOM_REPORT_NAME, generateExcel, getGroupIdList } from "../utils/common";
import {
  FETCH_BOM_LIST,
  FETCH_BOM_REPORT,
  FETCH_DASHBOARD_STATS,
  FETCH_GROUP_HIERARCHY,
} from "../apiClient/apiEndpoints";
import { useSelector } from "react-redux";
import moment from "moment";
import ImageView from "./Common/ImageView";

const BOMList = () => {
  const [slug, setSlug] = useState("");
  const [loading, setLoading] = useState(false);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const start = query.get("start") ? new Date(query.get("start")).toISOString() : null;
  const end = query.get("end") ? new Date(query.get("end")).toISOString() : null;
  const [reportLoading, setReportLoading] = useState({
    excel: false,
    pdf: false,
  });
  const { groupId } = useParams();
  const navigate = useNavigate();
  const [showProducts, setShowProducts] = useState(false);
  const [page, setPage] = useState(1);
  const [bomList, setBomList] = useState({});
  const [stats, setStats] = useState({});
  const [selectedBom, setSelectedBom] = useState({});
  const configData = useSelector((state) => state.config);
  const API_URL = configData?.config?.API_URL;
  const [showBOM, setShowBom] = useState(false);
  const [billImage, setBillImage] = useState("");
  const [groupIdList, setGroupIdList] = useState([]);

  const backButtonClick = () => {
    if (showProducts) {
      setShowProducts(false);
      setSelectedBom({});
    } else {
      navigate(-1);
    }
  };

  const fetchGroupHierarchy = async () => {
    const res = await apiClient.get(`${API_URL}${FETCH_GROUP_HIERARCHY}/${groupId}`);
    const list = getGroupIdList([res] || []);
    setGroupIdList(list);
    return list;
  };

  const fetchStats = async (groupIdList) => {
    const body = {};
    body.groupList = groupIdList;
    if (start && end) {
      body.filter = { start, end };
    }
    const res = await apiClient.post(`${API_URL}${FETCH_DASHBOARD_STATS}`, body);
    setStats(res);
  };

  const fetchBomList = async (groupIdList) => {
    const payload = {
      groupList: groupIdList,
      pageSize: configData.config?.DEFAULT_PAGE_SIZE || 10,
      pageNumber: page,
    };
    if (slug) {
      payload.query = slug.trim();
    }
    if (start && end) {
      payload.start = start;
      payload.end = end;
    }
    const res = await apiClient.post(`${API_URL}${FETCH_BOM_LIST}`, payload);
    setBomList(res);
  };

  const initializePage = async () => {
    setLoading(true);
    const groupList = await fetchGroupHierarchy();
    await Promise.all([fetchBomList(groupList), fetchStats(groupList)]);
    setLoading(false);
  };

  const handleViewProductList = (uuid) => {
    navigate(`/bom/${uuid}`);
  };

  const openBomImage = (uuid) => {
    setBillImage(bomList?.records?.find((item) => item.uuid === uuid)?.data?.image);
    setShowBom(true);
  };

  const closeBomImage = () => {
    setBillImage("");
    setShowBom(false);
  };

  const generateReport = async () => {
    setReportLoading((prev) => ({ ...prev, excel: true }));
    const payload = {
      groupList: groupIdList,
    };
    if (start && end) {
      payload.start = start;
      payload.end = end;
    }
    const res = await apiClient.post(`${API_URL}${FETCH_BOM_REPORT}`, payload);
    const reportData = res.map((item, idx) => ({
      "S.No.": idx + 1,
      "Unique Reference Number": item?.uuid || "N/A",
      Zone: item?.zone || "N/A",
      Branch: item?.branch || "N/A",
      Channel: item?.channel || "N/A",
      "Store Code": item?.data?.groupCode || "N/A",
      "Customer Email ID": item?.data?.email || "N/A",
      "User Salary Code": item?.salaryCode || "N/A",
      "Employee Name": item?.data?.employeeName || "N/A",
      "Customer Name": item?.data?.userName || "N/A",
      "Customer Mobile Number": item?.data?.mobile || "N/A",
      "Product Category": item?.data?.rootCategory || "N/A",
      "BoM Value": item?.data?.total || "N/A",
      Snapshot: item?.data?.image || "N/A",
      Address: item?.data?.address || "N/A",
      "Request Date & Time": moment(item?.createdAt)?.format("DD MMM YYYY HH:mm") || "",
    }));
    generateExcel(BOM_REPORT_NAME, reportData);
    setReportLoading((prev) => ({ ...prev, excel: false }));
  };

  useEffect(() => {
    initializePage();
  }, [slug.trim(), page, groupId, start, end]);

  return (
    <>
      <div className="product-main bomlist">
        <div className="page-tile pb-3">
          <h5>
            <span className='backbtn cursor-pointer ps-2 mt-2" ' title="Back" onClick={backButtonClick}>
              <BackIcon /> Back
            </span>
            {showProducts ? <> BOMs Product List </> : <> BOMs List </>}{" "}
          </h5>
          {!showProducts && (
            <div className="searchnadd">
              <form className=" app-search app-search1 d-flex justify-content-start ">
                <div className="position-relative w-100">
                  <SearchesCIcon />
                  {slug && (
                    <span
                      className="searchiconcancel"
                      onClick={() => {
                        setSlug("");
                      }}
                    >
                      <CancelIcon />
                    </span>
                  )}
                  <input
                    type="text"
                    name="searchName"
                    className="form-control"
                    placeholder="Search BOM"
                    autoComplete="off"
                    value={slug}
                    onChange={(e) => {
                      setSlug(e.target.value);
                    }}
                  />
                </div>
              </form>
              <button
                className="DefaultRedBtn download px-2"
                title="Download Excel"
                onClick={generateReport}
                disabled={reportLoading?.excel}
              >
                {reportLoading.excel ? <SpinnerIcon /> : <EXLDownloadIcon />}
              </button>
            </div>
          )}
        </div>
        {loading ? (
          <div className="tablenpage bomlistp">
            <div className="details-here">
              <p>
                <Skeleton animation="wave" variant="text" height="2rem" width="10rem" />{" "}
              </p>
            </div>
            <div className="table-responsive cate-table p-0 ">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th className="text-center">S.No.</th>
                    <th>Zone</th>
                    <th>Branch</th>
                    <th>Channel</th>
                    <th>Store Code</th>
                    <th>Customer Email ID</th>
                    <th>User Salary Code</th>
                    <th>Customer Name</th>
                    <th>Customer Mobile Number</th>
                    <th>Product Category</th>
                    <th className="text-center">Snapshots</th>
                    <th className="text-center">BOM Value</th>
                    <th className="text-center">Request Date & Time</th>
                    <th className="text-center">View Products</th>
                  </tr>
                </thead>
                <tbody>
                  {[...Array(4)].map((_, index) => (
                    <tr key={index}>
                      <td>
                        <div className="table-text">
                          <Skeleton animation="wave" variant="text" height="2rem" width="2rem" />
                        </div>
                      </td>
                      <td>
                        <div className="table-text justify-content-start">
                          <Skeleton animation="wave" variant="text" height="2rem" width="2rem" />
                        </div>
                      </td>
                      <td>
                        <div className="table-text justify-content-start">
                          <Skeleton animation="wave" variant="text" height="2rem" width="5rem" />
                        </div>
                      </td>
                      <td>
                        <div className="table-text justify-content-start">
                          <Skeleton animation="wave" variant="text" height="2rem" width="5rem" />
                        </div>
                      </td>
                      <td>
                        <div className="table-text justify-content-start">
                          <Skeleton animation="wave" variant="text" height="2rem" width="5rem" />
                        </div>
                      </td>
                      <td>
                        <div className="table-text justify-content-start">
                          <Skeleton animation="wave" variant="text" height="2rem" width="5rem" />
                        </div>
                      </td>
                      <td>
                        <div className="table-text justify-content-start">
                          <Skeleton animation="wave" variant="text" height="2rem" width="5rem" />
                        </div>
                      </td>
                      <td>
                        <div className="table-text justify-content-start">
                          <Skeleton animation="wave" variant="text" height="2rem" width="5rem" />
                        </div>
                      </td>
                      <td>
                        <div className="table-text justify-content-start">
                          <Skeleton animation="wave" variant="text" height="2rem" width="5rem" />
                        </div>
                      </td>
                      <td>
                        <div className="table-text justify-content-start">
                          <Skeleton animation="wave" variant="text" height="2rem" width="5rem" />
                        </div>
                      </td>
                      <td>
                        <div className="table-text justify-content-start">
                          <Skeleton animation="wave" variant="text" height="2rem" width="5rem" />
                        </div>
                      </td>
                      <td>
                        <div className="table-text justify-content-start">
                          <Skeleton animation="wave" variant="text" height="2rem" width="5rem" />
                        </div>
                      </td>
                      <td>
                        <div className="table-text justify-content-start">
                          <Skeleton animation="wave" variant="text" height="2rem" width="5rem" />
                        </div>
                      </td>
                      <td>
                        <div className="table-text justify-content-start">
                          <Skeleton animation="wave" variant="text" height="2rem" width="5rem" />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <>
            {!showProducts ? (
              <div className="tablenpage bomlistp">
                <div className="details-here">
                  <p> Total : &#8377; {new Intl.NumberFormat("en-IN").format(stats?.totalPrice)} </p>
                </div>
                <div className="table-responsive cate-table p-0 ">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th className="text-center">S.No.</th>
                        <th>Zone</th>
                        <th>Branch</th>
                        <th>Channel</th>
                        <th>Store Code</th>
                        <th>Customer Email ID</th>
                        <th>User Salary Code</th>
                        <th>Employee Name</th>
                        <th>Customer Name</th>
                        <th>Customer Mobile Number</th>
                        <th>Product Category</th>
                        <th className="text-center">Snapshots</th>
                        <th className="text-center">BOM Value</th>
                        <th className="text-center">Request Date & Time</th>
                        <th className="text-center">View Products</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bomList?.records?.map((bom, idx) => (
                        <tr key={bom.uuid}>
                          <td className="text-center">{idx + bomList.pagination.firstRecord}</td>
                          <td>{bom?.zone || "N/A"}</td>
                          <td>{bom?.branch || "N/A"}</td>
                          <td>{bom?.channel || "N/A"}</td>
                          <td>{bom?.data?.groupCode || "N/A"}</td>
                          <td> {bom?.data?.email || "N/A"} </td>
                          <td>{bom?.salaryCode || "N/A"}</td>
                          <td>{bom?.data?.employeeName || "N/A"}</td>
                          <td> {bom?.data?.userName || "N/A"} </td>
                          <td> {bom?.data?.mobile || "N/A"} </td>
                          <td> {bom?.data?.rootCategory || "N/A"} </td>
                          <td className="text-center">
                            {bom?.data?.image ? (
                              <span className="link" onClick={() => openBomImage(bom.uuid)}>
                                View
                              </span>
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td> {bom?.data?.total || "N/A"} </td>
                          <td className="text-center">{moment(bom?.createdAt).format("DD MMM YYYY HH:mm")}</td>
                          <td className="text-center">
                            <a onClick={() => handleViewProductList(bom.uuid)}>View List</a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="pagination">
                  <div className="current-stat">
                    {bomList?.pagination?.firstRecord} - {bomList?.pagination?.lastRecord} of{" "}
                    {bomList?.pagination?.totalRecords}
                  </div>
                  <div className="pages">
                    <Pagination
                      count={bomList?.pagination?.totalPage}
                      defaultPage={1}
                      page={page}
                      siblingCount={2}
                      onChange={(e, pageNumber) => setPage(pageNumber)}
                      color="primary"
                      sx={{
                        "& .MuiPaginationItem-root": {
                          color: "#333",
                          backgroundColor: "#f0f0f0",
                        },
                        "& .MuiPaginationItem-root.Mui-selected": {
                          color: "#fff",
                          backgroundColor: "#ca373c",
                        },
                        "& .MuiPaginationItem-root:hover": {
                          backgroundColor: "#dcdcdc",
                        },
                      }}
                      boundaryCount={2}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                {/* <div className="tablenpage">
                  <div className="table-responsive cate-table p-0 ">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="text-center">S.No.</th>
                          <th> Product Name</th>
                          <th className="text-center"> Product Price </th>
                          <th className="text-center"> Product Colour </th>
                          <th className="text-center"> Product Size </th>
                          <th className="text-center"> Quantity </th>
                          <th className="text-center"> Total Price </th>
                          <th className="text-center"> Date </th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedBom?.data?.products?.map((prdct, idx) => (
                          <tr key={idx}>
                            <td className="text-center">{idx + 1}</td>
                            <td> {prdct?.name} </td>
                            <td className="text-center"> &#8377; {prdct?.price || "N/A"} </td>
                            <td className="text-center"> {prdct?.category || "N/A"} </td>
                            <td className="text-center"> {prdct?.parentCategory || "N/A"} </td>
                            <td className="text-center"> {prdct?.quantity || "N/A"} </td>
                            <td className="text-center"> &#8377; {prdct?.totalPrice || "N/A"} </td>
                            <td className="text-center"> {moment(prdct?.date).format("DD MMM YYYY")} </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="total-lane">
                    <a onClick={() => downloadBom(selectedBom.uuid)} disabled={reportLoading.pdf}>
                      Download BOM(PDF)
                      {reportLoading.pdf && (
                        <span className="load-btn">
                          <svg viewBox="25 25 50 50">
                            <circle r="20" cy="50" cx="50"></circle>
                          </svg>
                        </span>
                      )}
                    </a>
                    <a onClick={downloadBomExcel} disabled={reportLoading.excel}>
                      Download BOM(Excel)
                      {reportLoading.excel && (
                        <span className="load-btn">
                          <svg viewBox="25 25 50 50">
                            <circle r="20" cy="50" cx="50"></circle>
                          </svg>
                        </span>
                      )}
                    </a>
                    Subtotal: &#8377; {selectedBom?.data?.total}
                  </div>
                </div> */}
              </>
            )}
          </>
        )}
      </div>
      <ImageView show={showBOM} snapShot={billImage} handleClose={closeBomImage} />
    </>
  );
};

export default BOMList;
