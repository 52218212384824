import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import useWindowDimensions from "../../utils/useWindowDimensions";

const findGroupNameRecursively = (data, uuid) => {
  for (const item of data || []) {
    if (item.uuid === uuid) {
      return item;
    }
    if (item?.children?.length > 0) {
      const found = findGroupNameRecursively(item.children, uuid);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

const AppUsage = ({ graphStats, groupHierarchy }) => {
  let { width } = useWindowDimensions();
  const [staticData, setStaticData] = useState([]);

  const labels = staticData?.map((item) => item?.label || item?.name);
  const guidedCounts = staticData?.map((item) => item?.count);

  useEffect(() => {
    if (groupHierarchy) {
      const staticData = Object.keys(graphStats || {})?.map((uuid) => ({
        name: findGroupNameRecursively([groupHierarchy], uuid)?.name || "",
        count: graphStats[uuid] || 0,
      }));
      for (let i = staticData.length; i < 5; i++) {
        staticData.push({
          name: "No Data",
          count: 0,
        });
      }
      setStaticData(staticData);
    } else {
      setStaticData(graphStats);
    }
  }, [graphStats]);

  const chartOptions = {
    chart: {
      type: "bar",
      width: "100%",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: labels,
      tickAmount: 15,
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    colors: ["#CA353B"],
  };

  const chartSeries = [
    {
      name: "Logins",
      data: guidedCounts,
    },
  ];

  return (
    <div id="chart">
      <ReactApexChart
        options={chartOptions}
        series={chartSeries}
        type="bar"
        height={width > 1400 && width < 1650 ? 210 : width >= 1650 ? 270 : 200}
      />
    </div>
  );
};

export default AppUsage;
