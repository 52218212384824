import React, { useState, useEffect } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";
import { DateFilterIcon } from "../../Assets/Icons";

function DateRangeFilter({ dates, setDates }) {
  const [mode, setMode] = useState("all");
  const [showPicker, setShowPicker] = useState(false);
  const [selectedRange, setSelectedRange] = useState({
    start: dates.start || new Date(),
    end: dates.end || new Date(),
  });

  useEffect(() => {
    document.body.classList.toggle("filter-active", showPicker);
    return () => {
      document.body.classList.remove("filter-active");
    };
  }, [showPicker]);

  const handleAllButtonClick = () => {
    setMode("all");
    setDates({ start: null, end: null });
    setSelectedRange({ start: null, end: null });
  };

  const handleApply = () => {
    setMode("custom");
    let dateSelection = null;
    if (selectedRange?.start === selectedRange?.end) {
      // Handling the one day date range (24 hours data)
      const endDate = new Date(selectedRange?.end);
      endDate.setHours(24, 0, 0, 0);
      dateSelection = { ...selectedRange, end: endDate };
    }
    setDates(dateSelection || selectedRange);
    setShowPicker(false);
  };

  const handleRangeChange = (ranges) => {
    if (ranges) {
      const startDate = new Date(ranges.selection.startDate);
      const endDate = new Date(ranges.selection.endDate);
      endDate.setHours(23, 59, 59, 99);
      setSelectedRange({
        start: startDate,
        end: endDate,
      });
    }
  };

  const closeDateModal = () => {
    setShowPicker(false);
  };

  const openDateModal = () => {
    setShowPicker(true);
  };

  return (
    <div className="daterangep mb-0">
      <div className="filteroption m-0">
        <span onClick={openDateModal} className="h-100">
          {mode === "custom" && selectedRange.start && selectedRange.end ? (
            `${moment(selectedRange.start).format("DD MMM, YY")} - ${moment(selectedRange.end).format("DD MMM, YY")}`
          ) : (
            <>
              <DateFilterIcon/>
              Date
            </>
          )}
        </span>
      </div>

      {showPicker && <div className="showoverlay" onClick={closeDateModal}></div>}

      {showPicker && (
        <div className="daterangepickerf">
          <DateRangePicker
            ranges={[
              {
                startDate: selectedRange.start,
                endDate: selectedRange.end,
                key: "selection",
              },
            ]}
            onChange={handleRangeChange}
            moveRangeOnFirstSelection={false}
            editableDateInputs={true}
            onClose={closeDateModal}
          />

          <div className="date-range-btns">
            <button className="DefaultRedBtn reset" onClick={handleAllButtonClick} style={{ fontSize: "0.75rem" }}>
              Reset
            </button>
            <button className="DefaultRedBtn" onClick={handleApply} style={{ fontSize: "0.75rem" }}>
              Confirm
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default DateRangeFilter;
