import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { AUTH_TOKEN, generateExcelFromArrayAndJson, savePdfFile } from "../utils/common";
import { useSelector } from "react-redux";
import { FETCH_BOM_DETAILS, FETCH_BOM_PDF } from "../apiClient/apiEndpoints";
import apiClient from "../apiClient";
import { BackIcon, EXLDownloadIcon, PDFDownloadIcon, SpinnerIcon } from "../Assets/Icons";

const BOMDetail = () => {
  const { bomId } = useParams();
  const navigate = useNavigate();
  const configData = useSelector((state) => state.config);
  const API_URL = configData?.config?.API_URL;
  const [selectedBom, setSelectedBom] = useState({});
  const [reportLoading, setReportLoading] = useState({
    excel: false,
    pdf: false,
  });

  const fetchBomData = async () => {
    const bomData = await apiClient.get(`${API_URL}${FETCH_BOM_DETAILS}/${bomId}`);
    setSelectedBom(bomData);
  };

  const downloadBom = async (bomId) => {
    setReportLoading((prev) => ({ ...prev, pdf: true }));
    const pdfName = `${selectedBom.data?.userName}_bill_of_material_${moment(selectedBom.createdAt).format(
      "DD-MMM-YYYY_HH_mm_ss"
    )}.pdf`;
    const response = await axios.get(`${API_URL}${FETCH_BOM_PDF}/${bomId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
      },
      responseType: "arraybuffer",
    });
    await savePdfFile(response.data, pdfName);
    setReportLoading((prev) => ({ ...prev, pdf: false }));
  };

  const downloadBomExcel = () => {
    // const storeData = [
    //   ["Zone", selectedBom?.zone],
    //   ["Branch", selectedBom?.branch],
    //   ["Store ID", selectedBom?.data?.groupCode],
    //   ["Salary Code", selectedBom?.salaryCode],
    //   ["Product Name", selectedBom?.data?.rootCategory],
    //   ["Name", selectedBom?.data?.userName],
    //   ["Date", selectedBom?.data?.date],
    //   ["BoM Link", `${window.location.origin}/bom/${selectedBom.uuid}`],
    //   ["SubTotal", selectedBom?.data?.total],
    // ];
    // const emptySpaces = [["", "", ""]];
    const productData = selectedBom?.data?.products?.map((item) => {
      return {
        "Product Category": selectedBom?.data?.rootCategory ? selectedBom?.data?.rootCategory : "",
        "LN Code": item?.lnCode ? item.lnCode : "",
        "Product Name": item?.name ? item.name : "",
        MRP: item?.price ? item.price : "",
        "Product Colour": item?.category ? item.category : "",
        "Product Size": item?.parentCategory ? item.parentCategory : "",
        "Product Image": item?.productImage ? item.productImage : "",
        Quantity: item?.quantity ? item.quantity : "",
        "Total Price": item?.totalPrice ? item.totalPrice : "",
        Zone: selectedBom?.zone,
        Branch: selectedBom?.branch,
        "Store ID": selectedBom?.data?.groupCode,
        "Salary Code": selectedBom?.salaryCode,
        "Product Name": selectedBom?.data?.rootCategory,
        Name: selectedBom?.data?.userName,
        Date: selectedBom?.data?.date,
        "BoM Link": `${window.location.origin}/bom/${selectedBom.uuid}`,
        SubTotal: selectedBom?.data?.total,
      };
    });
    const excelName = `${selectedBom.data?.userName || ""}_bom`;
    generateExcelFromArrayAndJson(excelName, [productData]);
  };

  useEffect(() => {
    fetchBomData();
  }, []);

  return (
    <>
      <div className="product-main bomlist">
        <div className="page-tile pb-3">
          <h5>
            <span className='backbtn cursor-pointer ps-2 mt-2" ' title="Back" onClick={() => navigate(-1)}>
              <BackIcon /> Back
            </span>
            BOMs Product Detail{" "}
          </h5>

          <div className="searchnadd">
            <button
              className="DefaultRedBtn download px-2"
              onClick={() => downloadBom(selectedBom.uuid)}
              disabled={reportLoading.pdf}
            >
              {reportLoading.pdf ? <SpinnerIcon /> : <PDFDownloadIcon />}
            </button>
            <button className="DefaultRedBtn download px-2" onClick={downloadBomExcel} disabled={reportLoading.excel}>
              {reportLoading.excel ? <SpinnerIcon /> : <EXLDownloadIcon />}
            </button>
          </div>
        </div>

        <div className="tablenpage">
          <div className="table-responsive cate-table p-0 ">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-center">S.No.</th>
                  <th className="text-center">Zone</th>
                  <th className="text-center">Branch</th>
                  <th className="text-center">Store ID</th>
                  <th className="text-center">Salary Code</th>
                  <th className="text-center">Product Category</th>
                  <th className="text-center"> LN Code </th>
                  <th className="text-center"> Product Name</th>
                  <th className="text-center"> MRP </th>
                  <th className="text-center"> Product Colour </th>
                  <th className="text-center"> Product Size </th>
                  <th className="text-center"> Quantity </th>
                  <th className="text-center"> Total Price </th>
                  <th className="text-center"> Date </th>
                </tr>
              </thead>
              <tbody>
                {selectedBom?.data?.products?.map((prdct, idx) => (
                  <tr key={idx}>
                    <td className="text-center">{idx + 1}</td>
                    <td className="text-center">{selectedBom?.zone || "N/A"}</td>
                    <td className="text-center">{selectedBom?.branch || "N/A"}</td>
                    <td className="text-center">{selectedBom?.data?.groupCode || "N/A"}</td>
                    <td className="text-center">{selectedBom?.salaryCode || "N/A"}</td>
                    <td className="text-center">{selectedBom?.data?.rootCategory || "N/A"}</td>
                    <td className="text-center"> {prdct?.lnCode || "N/A"} </td>
                    <td className="text-center"> {prdct?.name} </td>
                    <td className="text-center"> &#8377;{prdct?.price || "N/A"} </td>
                    <td className="text-center"> {prdct?.category || "N/A"} </td>
                    <td className="text-center"> {prdct?.parentCategory || "N/A"} </td>
                    <td className="text-center"> {prdct?.quantity || "N/A"} </td>
                    <td className="text-center"> &#8377;{prdct?.totalPrice || "N/A"} </td>
                    <td className="text-center"> {moment(prdct?.date).format("DD MMM YYYY")} </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="total-lane justify-content-end">Subtotal: &#8377; {selectedBom?.data?.total}</div>
        </div>
      </div>
    </>
  );
};

export default BOMDetail;
