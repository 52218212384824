import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BackIcon, EXLDownloadIcon, SpinnerIcon } from "../Assets/Icons";
import Skeleton from "@mui/material/Skeleton";
import Pagination from "@mui/material/Pagination";
import apiClient from "../apiClient";
import { useSelector } from "react-redux";
import moment from "moment";
import { FETCH_GROUP_HIERARCHY, FETCH_USER_SESSION_LIST } from "../apiClient/apiEndpoints";
import { generateExcel, getGroupIdList, USER_SESSION_REPORT } from "../utils/common";

const UserSessions = () => {
  const navigate = useNavigate();
  const { groupId } = useParams();
  const configData = useSelector((state) => state.config);
  const [loading, setLoading] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);
  const [page, setPage] = useState(1);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const start = query.get("start") ? new Date(query.get("start")).toISOString(): null;
  const end = query.get("end") ? new Date(query.get("end")).toISOString(): null;
  const [sessionList, setSessionList] = useState({});
  const [groupIdList, setGroupIdList] = useState([]);
  const { API_URL } = configData?.config;

  const backButtonClick = () => {
    navigate(-1);
  };

  const fetchGroupHierarchy = async () => {
    const res = await apiClient.get(`${API_URL}${FETCH_GROUP_HIERARCHY}/${groupId}`);
    const list = getGroupIdList([res] || []);
    setGroupIdList(list);
    return list;
  };

  const fetchSessionList = async (groupList) => {
    setLoading(true);
    const body = {
      groupList: groupList.filter((item) => item !== groupId),
      pageSize: configData.config?.DEFAULT_PAGE_SIZE || 10,
      pageNumber: page,
    };
    if (start && end) {
      body.start = start;
      body.end = end;
    }
    const res = await apiClient.post(`${API_URL}${FETCH_USER_SESSION_LIST}`, body);
    setSessionList(res);
    setLoading(false);
  };

  const initializePage = async () => {
    setLoading(true);
    const groupList = await fetchGroupHierarchy();
    await fetchSessionList(groupList);
    setLoading(false);
  };

  const generateReport = async () => {
    setReportLoading(true);
    const payload = {
      groupList: groupIdList.filter((item) => item !== groupId),
    };
    if (start && end) {
      payload.start = start;
      payload.end = end;
    }
    const res = await apiClient.post(`${API_URL}${FETCH_USER_SESSION_LIST}`, payload);
    const reportData = res.map((item, idx) => ({
      "S.No.": idx + 1,
      Zone: item?.zone || "N/A",
      Branch: item?.branch || "N/A",
      Channel: item?.channel || "N/A",
      "Store Code": item?.currentGroup?.identifier || "N/A",
      "No of Logins": item?.totalLogin || "N/A",
      "Last Login Date": item?.lastLogin ? moment(item?.lastLogin).format("DD MMM YYYY HH:mm") : "N/A",
      "No of BoM Created": item?.totalBom,
      "BoM Value": item?.bomSum,
    }));
    generateExcel(USER_SESSION_REPORT, reportData);
    setReportLoading(false);
  };

  useEffect(() => {
    initializePage();
  }, []);
  useEffect(() => {
    if (groupIdList.length > 0) {
      fetchSessionList(groupIdList);
    }
  }, [page, start, end]);

  return (
    <>
      <div className="product-main bomlist">
        <div className="page-tile pb-3">
          <h5>
            <span className='backbtn cursor-pointer ps-2 mt-2" ' title="Back" onClick={backButtonClick}>
              <BackIcon /> Back
            </span>
            User Session List
          </h5>
          <div className="searchnadd">
            <button
              className="DefaultRedBtn download px-2"
              title="Download Excel"
              onClick={generateReport}
              disabled={reportLoading}
            >
              {reportLoading ? <SpinnerIcon /> : <EXLDownloadIcon />}
            </button>
          </div>
        </div>
        {loading ? (
          <div className="tablenpage bomlistp">
            <div className="details-here">
              <p>
                <Skeleton animation="wave" variant="text" height="2rem" width="10rem" />{" "}
              </p>
            </div>
            <div className="table-responsive cate-table p-0 ">
              <table className="table table-bordered ">
                <thead>
                  <tr>
                    <th className="text-center">{""}</th>
                    <th className="text-center">S.No.</th>
                    <th>Zone</th>
                    <th>Branch</th>
                    <th>Channel</th>
                    <th>Store Code</th>
                    <th>No. of Logins</th>
                    <th>Last Login Date</th>
                    <th>No. of BoM Created</th>
                    <th>BoM Value (&#8377;) </th>
                  </tr>
                </thead>
                <tbody>
                  {[...Array(4)].map((_, index) => (
                    <tr key={index}>
                      <td>
                        <div className="table-text">
                          <Skeleton animation="wave" variant="text" height="2rem" width="2rem" />
                        </div>
                      </td>
                      <td>
                        <div className="table-text justify-content-start">
                          <Skeleton animation="wave" variant="text" height="2rem" width="2rem" />
                        </div>
                      </td>
                      <td>
                        <div className="table-text justify-content-start">
                          <Skeleton animation="wave" variant="text" height="2rem" width="5rem" />
                        </div>
                      </td>
                      <td>
                        <div className="table-text justify-content-start">
                          <Skeleton animation="wave" variant="text" height="2rem" width="5rem" />
                        </div>
                      </td>
                      <td>
                        <div className="table-text justify-content-start">
                          <Skeleton animation="wave" variant="text" height="2rem" width="5rem" />
                        </div>
                      </td>
                      <td>
                        <div className="table-text justify-content-start">
                          <Skeleton animation="wave" variant="text" height="2rem" width="5rem" />
                        </div>
                      </td>
                      <td>
                        <div className="table-text justify-content-start">
                          <Skeleton animation="wave" variant="text" height="2rem" width="5rem" />
                        </div>
                      </td>
                      <td>
                        <div className="table-text justify-content-start">
                          <Skeleton animation="wave" variant="text" height="2rem" width="5rem" />
                        </div>
                      </td>
                      <td>
                        <div className="table-text justify-content-start">
                          <Skeleton animation="wave" variant="text" height="2rem" width="5rem" />
                        </div>
                      </td>
                      <td>
                        <div className="table-text justify-content-start">
                          <Skeleton animation="wave" variant="text" height="2rem" width="5rem" />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <>
            <div className="tablenpage bomlistp">
              <div className="table-responsive cate-table p-0 ">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th className="text-center">S.No.</th>
                      <th>Zone</th>
                      <th>Branch</th>
                      <th>Channel</th>
                      <th>Store Code</th>
                      <th>No. of Logins</th>
                      <th>Last Login Date</th>
                      <th>No. of BoM Created</th>
                      <th>BoM Value (&#8377;) </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sessionList?.records?.map((session, idx) => (
                      <tr key={session.groupId}>
                        <td className="text-center">{idx + sessionList.pagination.firstRecord}</td>
                        <td>{session?.zone || "N/A"}</td>
                        <td>{session?.branch || "N/A"}</td>
                        <td> {session?.channel || "N/A"} </td>
                        <td> {session?.currentGroup?.identifier || "N/A"} </td>
                        <td> {session?.totalLogin ?? "N/A"} </td>
                        <td> {session?.lastLogin ? moment(session?.lastLogin).format("DD MMM YYYY HH:mm") : "N/A"} </td>
                        <td className="text-center">{session?.totalBom ?? "N/A"}</td>
                        <td className="text-center">{session?.bomSum ?? "N/A"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="pagination">
                <div className="current-stat">
                  {sessionList?.pagination?.firstRecord} - {sessionList?.pagination?.lastRecord} of{" "}
                  {sessionList?.pagination?.totalRecords}
                </div>
                <div className="pages">
                  <Pagination
                    count={sessionList?.pagination?.totalPage}
                    defaultPage={1}
                    page={page}
                    siblingCount={2}
                    onChange={(e, pageNumber) => setPage(pageNumber)}
                    color="primary"
                    sx={{
                      "& .MuiPaginationItem-root": {
                        color: "#333",
                        backgroundColor: "#f0f0f0",
                      },
                      "& .MuiPaginationItem-root.Mui-selected": {
                        color: "#fff",
                        backgroundColor: "#ca373c",
                      },
                      "& .MuiPaginationItem-root:hover": {
                        backgroundColor: "#dcdcdc",
                      },
                    }}
                    boundaryCount={2}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UserSessions;
